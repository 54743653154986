import "./module/responsive.js"
import Swiper from 'swiper'; 
var myswiper = new Swiper('.swiper-container', {
    centeredSlides: true,
    effect: 'fade',
    speed: 3500,
    simulateTouch: false,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    }
});

import ScrollMagic from 'scrollmagic';
class ScrollFade {
    constructor() {
        this.ANIMATION_CLASS = 'is-animation';

        let $section = document.querySelectorAll('.scroll:not(.is-animation)');
        if($section.length === null) {
            return;
        }
        let controller = new ScrollMagic.Controller();
        for(let i = 0; i < $section.length; i++) {
            let scene = new ScrollMagic.Scene({
                triggerElement: $section[i],
                triggerHook: 'onEnter',
                reverse: false,
                offset: 100
            })
                .addTo(controller);
            scene.on('enter', ()=>{
                $section[i].classList.add(this.ANIMATION_CLASS);
            });
            scene.on('end', ()=>{
                scene.destroy(true);
            });
        }
    }
}

new ScrollFade();