import $ from 'jquery';

$(function() {
    var $header = $('#header');

    // Nav Toggle Button
    $('#toggle').click(function(){
        $header.toggleClass('is-open');
    });
    
});
